body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.App {
  text-align: center;
}

.navbar{
  height: 15%;
  font-size: 18px;
  background-color: black;
  font-family: 'Manjari', sans-serif;
  box-shadow: 1px 5px 10px white;
}

.nav-link:hover {
  text-shadow: 2px 2px 4px white;
}

#Home {
  background-color: black;
  height: 95vh;
  width: 100vw;
  display: table-cell;
  vertical-align: middle;
}

.title-container {
  padding-bottom: 40px;
  margin: auto;
  height: 25%;
  width: 80%;
  color: white;
  text-align: center;
  font-family: 'Manjari', sans-serif;
  border: 5px solid #03adfc;
  border-radius: 9px;
}

.spots-page {
  background-color: black;
  height: 100vh;
  padding-top: 100px;
}

#home-title {
  position: relative;
  top: 50%;
}

.spots-container {
  text-align: center;
  border: 5px solid white;
  border-radius: 5px;
  margin-left: 7%;
}

.skatespot-info {
  margin-top: 10%;
}

.login-page {
  text-align: center;
  height: 95vh;
  width: 100%;
  background-color: rgba(0,0,255,0.5);
}

.login-container {
  width: 50%;
  margin:auto;
  padding-top: 10%;
}

@media (max-width: 450px) {
  .login-container {
    width: 75%;
  }
}

.login-form {
  background-color: white;
  border: 2px solid black;
  border-radius: 5px;
}

.one-click-sign-in {
  height: 20%;
  background-color: white;
  border: 2px solid black;
  border-radius: 5px;
}

.athlete-info-page, .sponsor-info-page {
  height: 100vh;
  background-color: black;
}

.athlete-social-card {
  border: 5px black solid;
  background-color: white;
  height: 100%;
  width: 30%;
  float: left;
  text-align: center;
}

.athlete-social-icons-row {
  height: 50px;
  width: 70px;
}

.athlete-info, .sponsor-info {
  background-color: white;
  height: 60%;
  border: 5px black solid;
}

.athlete-info-description, {
  background-color: white;
  height: 100%;
}

.athlete-video-parts {
  height: 240px;
  background-color: white;
  border: 5px black solid;
}

.savedSkateSpots {
  margin-top: 20px;
  margin-right: 80px;
  float: right;
  height: 170px;
  width: 900px;
  border-radius: 9px;
  border-style: solid;
}

.SavedSkateSpot {
  height: 120px;
  width: 140px;
  background-color: rgb(225, 231, 242, 0.9);
  float: left;
  border-style: solid;
  border-radius: 7px;
  margin-left: 40px;
  margin-top: 20px;
  text-align: center;
}

.profilePicture {
  height: 200px;
  width: 200px;
  border-radius: 50%;
  border-style: solid;
  background-image: url(/static/media/haroldHunter.a4c21dc5.jpeg);
  background-size: 100% 100% ;
  margin-top: 5px;
  margin-left: 20px;
  margin-bottom: 5px;
}

.card-img {
  height: 100%;
  width: 100%;
  border: 1px solid white;
}

.more-info-button {
  height: 20%;
  font-size: 20px;
}

.social-icon, .website-icon {
  height: 50px;
}

.sponsors, .athletes {
  background-color: black;
  padding-top: 20px;
}

.sponsor-card {
  color: white;
}

